.main_navbar {
  // position & size!
  @apply sticky top-0 w-full py-2;

  // z-index & shadow!
  @apply z-20 shadow-sm;

  // colors!
  @apply bg-accent-500 text-white border-accent-500;

  // transition!
  @apply transition-shadow duration-300;
}

.raised {
  @apply shadow-2xl;
}

.navbar_container {
  @apply flex items-center justify-between flex-wrap container mx-auto;
}

.links_menu {
  @apply text-sm md:flex-1 text-center md:ltr:text-right;
}

.link {
  // display!
  @apply block lg:inline-block;

  // padding & margins!
  @apply py-3 m-6 lg:m-0 lg:ltr:mr-6 lg:rtl:ml-6 lg:py-0;

  // borders!
  @apply border-t border-b lg:border-0 border-accent-500;

  // hover effect!
  @apply hover:text-primary-200;
}

.link_active {
  @apply text-primary-200 font-semibold;
}

.menu_toggle {
  @apply flex items-center px-3 py-2 border rounded;
}

.navbar_menu {
  @apply w-full flex-grow md:flex md:items-center md:w-auto;
}
