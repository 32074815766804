.dropdown_container {
  @apply relative;
}

.dropdown_toggle {
  @apply flex items-center justify-center;
}

.dropdown_menu {
  @apply absolute w-full mt-2 rounded-lg shadow-lg border bg-white md:w-48 overflow-hidden;
}

.dropdown_menu_ltr {
  @apply right-1/2 transform translate-x-1/2;
}

.dropdown_menu_rtl {
  @apply left-1/2 transform -translate-x-1/2;
}

.dropdown_menu_top {
  @apply -bottom-full mb-12;
}

.dropdown_item {
  @apply flex w-full items-center px-4 py-3 text-sm text-dark-100 hover:bg-primary-A800 border-b cursor-pointer;

  &:last-child {
    @apply border-b-0;
  }
}
