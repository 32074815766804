.main_navbar{
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  position: sticky;
  top: 0px;
  width: 100%;
  --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  z-index: 20;
  --tw-bg-opacity: 1;
  background-color: rgba(29, 67, 84, var(--tw-bg-opacity));
  --tw-border-opacity: 1;
  border-color: rgba(29, 67, 84, var(--tw-border-opacity));
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
  transition-property: box-shadow;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
  transition-duration: 300ms
}

.raised{
  --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.navbar_container{
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem
}

@media (min-width: 540px){
  .navbar_container{
    max-width: 540px
  }
}

@media (min-width: 720px){
  .navbar_container{
    max-width: 720px
  }
}

@media (min-width: 960px){
  .navbar_container{
    max-width: 960px
  }
}

@media (min-width: 1140px){
  .navbar_container{
    max-width: 1140px
  }
}

.navbar_container{
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  margin-left: auto;
  margin-right: auto
}

.links_menu{
  font-size: 0.875rem;
  line-height: 1.25rem;
  text-align: center
}

@media (min-width: 768px){
  .links_menu{
    flex: 1 1 0%
  }

  [dir='ltr'] .links_menu,[dir='ltr'].links_menu{
    text-align: right
  }

  [dir='ltr'] .md\:ltr\:text-right,[dir='ltr'].md\:ltr\:text-right{
    text-align: right
  }
}

.link{
  display: block
}

@media (min-width: 1024px){
  .link{
    display: inline-block
  }
}

.link{
  margin: 1.5rem;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem
}

@media (min-width: 1024px){
  .link{
    margin: 0px
  }

  [dir='ltr'] .link,[dir='ltr'].link{
    margin-right: 1.5rem
  }

  [dir='ltr'] .lg\:ltr\:mr-6,[dir='ltr'].lg\:ltr\:mr-6{
    margin-right: 1.5rem
  }

  [dir='rtl'] .link,[dir='rtl'].link{
    margin-left: 1.5rem
  }

  [dir='rtl'] .lg\:rtl\:ml-6,[dir='rtl'].lg\:rtl\:ml-6{
    margin-left: 1.5rem
  }

  .link{
    padding-top: 0px;
    padding-bottom: 0px
  }
}

.link{
  --tw-border-opacity: 1;
  border-color: rgba(29, 67, 84, var(--tw-border-opacity));
  border-top-width: 1px;
  border-bottom-width: 1px
}

@media (min-width: 1024px){
  .link{
    border-width: 0px
  }
}

.link:hover{
  --tw-text-opacity: 1;
  color: rgba(88, 173, 54, var(--tw-text-opacity))
}

.link_active{
  font-weight: 600;
  --tw-text-opacity: 1;
  color: rgba(88, 173, 54, var(--tw-text-opacity))
}

.menu_toggle{
  border-radius: 0.25rem;
  border-width: 1px;
  display: flex;
  align-items: center;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 0.75rem;
  padding-right: 0.75rem
}

.navbar_menu{
  flex-grow: 1;
  width: 100%
}

@media (min-width: 768px){
  .navbar_menu{
    display: flex;
    align-items: center;
    width: auto
  }
}