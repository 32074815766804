.dropdown_container{
  position: relative
}

.dropdown_toggle{
  display: flex;
  align-items: center;
  justify-content: center
}

.dropdown_menu{
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  border-radius: 0.5rem;
  border-width: 1px;
  margin-top: 0.5rem;
  overflow: hidden;
  position: absolute;
  --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  width: 100%
}

@media (min-width: 768px){
  .dropdown_menu{
    width: 12rem
  }
}

.dropdown_menu_ltr{
  right: 50%;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  transform: translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  --tw-translate-x: 50%
}

.dropdown_menu_rtl{
  left: 50%;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  transform: translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  --tw-translate-x: -50%
}

.dropdown_menu_top{
  margin-bottom: 3rem;
  bottom: -100%
}

.dropdown_item:hover{
  --tw-bg-opacity: 1;
  background-color: rgba(226, 247, 217, var(--tw-bg-opacity))
}

.dropdown_item{
  border-bottom-width: 1px;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 0.875rem;
  line-height: 1.25rem;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  padding-left: 1rem;
  padding-right: 1rem;
  --tw-text-opacity: 1;
  color: rgba(31, 31, 31, var(--tw-text-opacity));
  width: 100%
}

.dropdown_item:last-child{
  border-bottom-width: 0px
}