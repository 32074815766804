.basic {
  @apply px-5 py-2 rounded-lg inline-flex items-center justify-center hover:shadow;
}

.primary {
  @apply bg-primary-200 text-white hover:bg-primary-300;
}

.accent {
  @apply bg-accent-500 text-white hover:bg-accent-100;
}

.stroked {
  @apply bg-transparent border shadow-none;
}

.primaryStroked {
  @apply border-primary-300 text-primary-300;
}

.accentStroked {
  @apply border-accent-100 hover:border-accent-100 text-accent-100 hover:text-accent-100;
}

.fullWidth {
  @apply w-full;
}
